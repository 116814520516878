.wrapper {
  position: fixed;
  bottom: 100px;
  left: 20px;
  right: 20px;
  z-index: 11002;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
  pointer-events: none;

  bottom: 0;
  left: 0;
  right: 0;

  padding: 20px;
  background: rgba(255, 255, 255, 0);
  overflow-y: scroll;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);

  transition: 0.3s;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11001;
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
  transition-behavior: allow-discrete;
  backdrop-filter: blur(0px);
  transition: 0.3s;

  body:has(.eventDetail) & {
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    pointer-events: all;
    cursor: pointer;
    backdrop-filter: blur(4px) brightness(90%) contrast(110%);
  }
}

.eventDetail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.99);
  flex: 1 1 100%;
  pointer-events: all;
  max-width: 600px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  transition: 0.3s;
  transition-behavior: allow-discrete;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  font-size: 10px;

  :is(body, html):has(&) {
    overflow: hidden;
  }

  & pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  @starting-style {
    transition: 0.3s;
    opacity: 0;
    transform: translateX(-5px);
  }
}

.eventDetailTitle {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.eventDetailContext {
  padding-left: 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.eventData {
  padding: 8px;
  background: black;
  color: white;
  white-space: pre;
  font-size: 10px;
  font-family: monospace;
  border-radius: 5px;
  overflow-x: auto;
  min-height: min-content;
}

.events {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  flex-direction: column-reverse;
  overflow-y: auto;
  flex: 0 0 auto;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid white;
}

.event {
  height: 35px;
  flex: 0 0 35px;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 600;
  transform: translateY(0px);
  transition: 0.5s;
  user-select: none;

  &:first-child {
    border-bottom: none;
  }

  @starting-style {
    opacity: 0;
    transform: translateY(39px);
    margin-top: -39px;
  }

  .events:has([data-is-active='true']) & {
    background: #f7f7f7;
  }

  &&[data-is-active='true'] {
    background: #fff;
  }
}
